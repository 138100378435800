jQuery(document).ready(function($){


    $(".scrollup").click(function() {
        $('html, body').animate({
            scrollTop: 0
        }, 800);
        return false;
    });

    $(window).on("scroll load", Foundation.util.throttle(function() {
        if ($(this).scrollTop() > 98){
            $(".scrollup").show();
        }
        else{
            $(".scrollup").hide();
        }
    }, 300));



});
